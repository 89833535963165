import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
//import useLiveControl from "../controlled/useLiveControl"

const ReferralProgramDetail = ({ data }) => {
 // const { isStaging } = useLiveControl()
  
  return (
    <Layout>
      <Seo
        title={data.wpgraphql.page.title}
        description={
          "EnerBank's Contractor Referral Program rewards individuals or entities when they refer a home improvement contractor to EnerBank. Click to see how it works."
        }
      />
      {/* section-gap */}
      <section className="container">
        <h1 className="text-center mt-5 mb-3">{data.wpgraphql.page.title}</h1>
          <p className="text-center mb-5">Submit a referral <Link className="eb-link" to="https://info.enerbank.com/contractor-referral">here</Link></p>
        <div className="row">
          <div className="col-12">
            {data.wpgraphql.page.terms.terms.map((item, i) => (
              <div key={item.fieldGroupName + i} className="mb-5">
                <h3 className="text-blue mb-1">{item.question}</h3>
                <div dangerouslySetInnerHTML={{ __html: item.answer }} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default ReferralProgramDetail

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }

        terms {
          fieldGroupName
          terms {
            answer
            question
            fieldGroupName
          }
        }
      }
    }
  }
`
